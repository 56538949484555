import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { formValidate } from "../helpers/formValidate";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import useForm from "../hooks/useForm";
import Background from "../assets/loginBackground.jpg";
import { SignInService } from "../services/loginService";
import { useDispatch } from "react-redux";
import { setIsLogin } from "../redux/slice/authSlice";
import { getProfileDetails } from "../services/profilrService";
import { setProfileDetails } from "../redux/slice/profileSlice";

const validation = {
  email: { required: true, message: "Email is required" },
  password: { required: true, message: "Password is required" },
};

const Login = () => {
  const dispatch = useDispatch();
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const backgroundStyle = {
    backgroundColor: "#f1f5f9",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({
    color: "rose-500",
    message: null,
  });
  const [loaded, setLoaded] = useState(true);

  const authToken = localStorage.getItem("auth_token");

  useEffect(() => {
    if (authToken) {
      dispatch(setIsLogin(true));
    } else {
      dispatch(setIsLogin(false));
    }
  }, [authToken]);

  const onSubmit = async (values) => {
    setLoaded(false);
    try {
      const res = await SignInService({
        email: values?.email,
        password: values?.password,
      });
      if (res?.data?.status === 200) {
        setErrorMessage({ color: "green-500", message: res?.data?.message });
        dispatch(setIsLogin(true));
        localStorage.setItem("auth_token", res?.data?.accessToken);
        localStorage.setItem("refreshToken", res?.data?.refreshToken);
        toast.info(res.data.message);
        setTimeout(() => {
          navigate("/");
        }, 1000);
        getProfileDetails().then((res) => {
          if (res?.status === 200) {
            dispatch(setProfileDetails({ ...res?.data, role: "USER" }));
          } else {
            dispatch(setProfileDetails({}));
          }
        });
      } else if (res?.data?.status === 400) {
        setErrorMessage({
          color: "red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      } else {
        setErrorMessage({
          color: "red-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      }
    } catch (error) {
      console.error("Error signing in:", error);
      toast.info("Something went wrong!!!");
      setErrorMessage({
        color: "red-500",
        message:
          error?.response?.data?.message ||
          error.message ||
          "Something went wrong!!!",
      });
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setLoaded(true);
    }
  };
  return (
    <div
      className="w-full min-h-screen flex items-center justify-center py-6 px-4 lg:px-8"
      style={backgroundStyle}
    >
      <div className="w-full max-w-md bg-transparent  backdrop-blur-sm border-2 border-victoria py-8 rounded-2xl shadow-lg">
        <div className="flex min-h-full flex-1 flex-col justify-center px-4 py-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-20 w-auto"
              src="https://unicorncloths.com/assets/img/unicorn.svg"
              alt="Your Company"
            />
            <h2 className="mt-2 text-center text-2xl font-extrabold text-white">
              Please Use Your Credentials to Login
            </h2>
            {errorMessage && (
              <h4
                className={`mt-2 text-center text-md font-bold text-${errorMessage.color}`}
              >
                {errorMessage.message}
              </h4>
            )}
          </div>
          <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
              <div className="mt-4">
                <Input
                  label="Email"
                  labelClasses="!text-md !text-victoria !font-bold"
                  inputType="text"
                  inputPlaceholder="Enter your email"
                  value={values?.email}
                  inputName="email"
                  onChange={handleChange}
                  {...formValidate(errors, "email")}
                />
              </div>
              <div className="mt-4">
                <Input
                  label="Password"
                  labelClasses="!text-md !text-victoria !font-bold"
                  inputType="password"
                  inputPlaceholder="Enter your password"
                  value={values?.password}
                  inputName="password"
                  onChange={handleChange}
                  {...formValidate(errors, "password")}
                />
              </div>
              <div className="mt-6">
                <Button
                  buttonClasses="w-full text-white bg-victoria hover:bg-secondaryColor focus:outline-none justify-center rounded-md"
                  buttonHasLink={false}
                  buttonType="submit"
                  buttonLabel="Log in"
                  buttonIconPosition="left"
                  buttonIcon="fa-light fa-right-to-bracket"
                />
              </div>
              {/* Uncomment and adjust if needed */}
              <div className="mt-4 text-end">
                <div className="text-sm">
                  <Link
                    to="/sign-up"
                    className="font-semibold text-victoria hover:text-secondaryColor"
                  >
                    {" Or Sign Up ->"}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
