import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formValidate } from "../helpers/formValidate";
import Input from "../components/form/Input";
import Button from "../components/form/Button";
import useForm from "../hooks/useForm";
import Background from "../assets/loginBackground.jpg";
import {
  getRoleCodeList,
  SignInService,
  signupUser,
  verifyOtpUser,
} from "../services/loginService";
import { useDispatch } from "react-redux";
import { setIsLogin } from "../redux/slice/authSlice";
import { getProfileDetails } from "../services/profilrService";
import { setProfileDetails } from "../redux/slice/profileSlice";

const validation = {
  otp: { required: true, message: "Otp is required" },
};

const VerifyOtp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const backgroundStyle = {
    backgroundColor: "#f1f5f9",
    backgroundImage: `url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(true);
  const email = location.state;

  const onSubmit = async (values) => {
    setLoaded(false);
    try {
      const res = await verifyOtpUser({
        otp: values?.otp,
        email: email,
      });
      if (res?.data?.status === 200) {
        toast.info(res.data.message);
        navigate("/login");
      } else if (res?.data?.status === 400) {
        toast.info(res?.data?.message || res?.data?.errors);
      } else {
        toast.info(res?.data?.message || res?.data?.errors);
      }
    } catch (error) {
      console.error("Error signing in:", error);
      toast.info("Something went wrong!!!");
    } finally {
      setLoaded(true);
    }
  };
  return (
    <div
      className="w-full min-h-screen flex items-center justify-center py-6 px-4 lg:px-8"
      style={backgroundStyle}
    >
      <div className="w-full max-w-md bg-transparent  backdrop-blur-sm border-2 border-victoria py-8 rounded-2xl shadow-lg">
        <div className="flex min-h-full flex-1 flex-col justify-center px-4 py-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-16 w-auto"
              src="https://unicorncloths.com/assets/img/unicorn.svg"
              alt="Your Company"
            />
            <h2 className="mt-2 text-center text-2xl font-extrabold text-white">
              Verify Otp
            </h2>
          </div>
          <div className="mt-1 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
              <div className="mt-1">
                <Input
                  label="OTP"
                  labelClasses="!text-md !text-victoria !font-bold"
                  inputType="number"
                  inputPlaceholder="Enter your otp"
                  value={values?.otp}
                  inputName="otp"
                  onChange={handleChange}
                  {...formValidate(errors, "otp")}
                />
              </div>
              <div className="mt-6">
                <Button
                  buttonClasses="w-full text-white bg-victoria hover:bg-secondaryColor focus:outline-none justify-center rounded-md"
                  buttonHasLink={false}
                  buttonType="submit"
                  buttonLabel="Verify"
                  buttonIconPosition="left"
                  buttonIcon="fa-light fa-right-to-bracket"
                />
              </div>
              <div className="mt-6">
                <Button
                  buttonClasses="w-full text-white bg-victoria hover:bg-secondaryColor focus:outline-none justify-center rounded-md"
                  buttonHasLink={false}
                  buttonType="button"
                  buttonLabel="Bact to sign up"
                  buttonFunction={() => navigate("/sign-up")}
                />
              </div>
              {/* Uncomment and adjust if needed
            <div className="mt-1 text-end">
              <div className="text-sm">
                <Link
                  to="/forgotPassword"
                  className="font-semibold text-victoria hover:text-secondaryColor"
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
